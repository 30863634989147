// javascript:void(0);
// xl={{ span: 10, offset: 1 }}
@import "color";
@import "mixin";
@import "Animation";
@import "Fonts";
@import "custome";
@import "Buttons.scss";
@import "Form";
@import "Navbar.scss";
@import "login";
@import "InnerPage";

.errormsg{
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545;
}
.dnuCyW{
    background-color: #AC7AF7 !important;
}
.ccaWwZ {
    font-family: 'Raleway', 'sans-serif' !important;
    max-width: 460px !important;
    width: 100% !important;
    padding: 10px 20px !important;
}
.clkUpo{
    background-color: #AC7AF7 !important;
}
.ccaWwZ *{
    font-family: 'Raleway', 'sans-serif' !important;

}
.fvvOxi{
    color: #AC7AF7 !important;
}
.dpXFsa:enabled:hover{
    background-color: #AC7AF7 !important;
}
.fIirMf{
    background-color: transparent !important;
}
.jYHbmO{
    background-color: transparent !important;
}
.invalidwallet{
    color: red;
}
@media(max-width:480px){
    .Toastify__toast-container{
        max-width: 300px;
        left: calc(50% - 150px);
        margin-bottom: 35px;
    }
}
.Toastify__close-button{
    color: #6B7280;
    opacity: 1;
    position: relative;
    top: 5px;
    right: 8px;
    svg{
        width: 20px;height: 20px;
    }
}
.Toastify__close-button > svg {
    fill: black !important;
    width: 23px !important;
}