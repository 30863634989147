h1{font-size: 50px;
    @media(max-width:991px) {font-size: 40px;}
    @media(max-width:767px) {font-size: 35px;}
    @media(max-width:575px) {font-size: 30px;}
}
h2{font-size: 40px;font-weight: 500;
    @media(max-width:991px){font-size: 35px;}
    @media(max-width:767px){font-size: 32px;}
    @media(max-width:575px){font-size: 28px;}
}
h3{font-size: 30px;
    @media(max-width:767px){font-size: 26px;}
    @media(max-width:575px){font-size: 22px;}
}
h4{font-size: 22px;
    @media(max-width:767px){font-size: 20px;}
    @media(max-width:575px){font-size: 18px;}
}
h5{font-size: 20px;font-weight: 500;}
h6{font-size: 16px;font-weight: 500;}
p{font-size: 16px;font-weight: 400;}