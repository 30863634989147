/* Animation */
@keyframes Navanime {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@-webkit-keyframes Navanime {
  0% {
    -webkit-transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    opacity: 1;
  }
}
@-moz-keyframes Navanime {
  0% {
    -moz-transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    -moz-transform: translateY(0px);
    opacity: 1;
  }
}
@-webkit-keyframes transright {
  0% {
    -webkit-transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}
@-moz-keyframes transright {
  0% {
    -moz-transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -moz-transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes transright {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@-webkit-keyframes transleft {
  0% {
    -webkit-transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}
@-moz-keyframes transleft {
  0% {
    -moz-transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -moz-transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes transleft {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@-webkit-keyframes transup {
  0% {
    -webkit-transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    opacity: 1;
  }
}
@-moz-keyframes transup {
  0% {
    -moz-transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -moz-transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes transup {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@-webkit-keyframes transdown {
  0% {
    -webkit-transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0px);
    opacity: 1;
  }
}
@-moz-keyframes transdown {
  0% {
    -moz-transform: translateY(50px);
    opacity: 0;
  }

  100% {
    -moz-transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes transdown {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes opacityanime {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes opacityanime {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-moz-keyframes opacityanime {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
/* Animation */
