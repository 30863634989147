.form-control{
    height: 65px;border-color: transparent;@include bd-radius(10px);
    padding-left: 30px;
    padding-right: 50px;
    color: $black;font-weight: 500;font-size: 20px;box-shadow: 0 0px 4px rgba(0,0,0,0.05);
    &::-ms-input-placeholder{color: $lightgrey;font-weight: 500;font-size: 20px;}
    &::-webkit-input-placeholder{color: $lightgrey;font-weight: 500;font-size: 20px;}  
    &::placeholder{color: $lightgrey;font-weight: 500;font-size: 20px;}
    &:focus{box-shadow: 0 10px 10px rgba(172,122,247,0.5); border-color: $color;}
}
label{color: $textcolor;font-weight: 500;}
.icon-cntnr{
    position: absolute;right: 30px;top: 22px;
    z-index: 2;color: $lightgrey;cursor: pointer;
    i{color: $lightgrey;}
    &:hover{color: $color;}
}


.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    border-color: transparent !important;
    box-shadow:transparent !important;
}

.form-control.is-invalid{
    border-color: none !important;
}